import { createContext, useContext } from 'react'

// Types
import { ICompany } from '../common/models/Company'
import { ICompanyMetadataPure } from '../common/models/CompanyMetadata'
import { ICompanyFeedbackAggregation } from '../common/models/Feedback'
import { IAlertPopup, INotification } from '../common/models/IAlertPopup'
import { IGoal, IPdp } from '../common/models/Pdp'
import { IRequest } from '../common/models/Request'
import { IUser } from '../common/models/User'
import { AnyColor, ThemeColor } from '../common/models/colors'
import { IOnboardingInstancePopulated } from '../common/models/OnboardingInstance'

// Styles
import { baseColors, createColors } from '../styles/colors'

export type UserAuthToken = string | undefined

export interface ITheme {
  id: number
  baseColors: {
    [key in ThemeColor]: number[]
  }
  colors: {
    [key in AnyColor]: string
  }
}

export interface ILoadingState {
  shouldLoadUser: boolean
  shouldLoadCompany: boolean
  shouldLoadRequests: boolean
  shouldLoadPersonalPdp: boolean
  shouldLoadNewToken: boolean
  shouldLoadFeedbackStatistics: boolean
  shouldLoadFeedbackAggregations: boolean
  shouldLoadOnboardingInstance: string | undefined
}

// Types
export type State = {
  loadingState: ILoadingState
  userAuthToken?: UserAuthToken
  user?: IUser
  theme: ITheme
  company?: ICompany
  newCompanySlug?: string
  userPdp?: IPdp
  alertPopup?: IAlertPopup
  notification?: INotification
  requests: IRequest[]
  feedbackAggregations?: ICompanyFeedbackAggregation
  companyMetaData?: ICompanyMetadataPure
  onboardingInstance?: IOnboardingInstancePopulated
}

export const DEFAULT_APP_STATE: State = {
  loadingState: {
    shouldLoadUser: true,
    shouldLoadCompany: false,
    shouldLoadRequests: true,
    shouldLoadPersonalPdp: true,
    shouldLoadNewToken: false,
    shouldLoadFeedbackStatistics: true,
    shouldLoadFeedbackAggregations: false,
    shouldLoadOnboardingInstance: undefined,
  },
  theme: {
    id: 0,
    baseColors,
    colors: createColors(baseColors),
  },
  requests: [],
  userAuthToken: undefined,
}

// Actions
export type updateStateAction = {
  type: 'updateState'
  payload: State
}
export type updateUserAction = {
  type: 'updateUser'
  payload: IUser | undefined
}

export type updateThemeAction = {
  type: 'updateTheme'
  payload: ITheme
}

export type updateCompanyAction = {
  type: 'updateCompany'
  payload?: ICompany
}

export type updateNewCompanySlugAction = {
  type: 'updateNewCompanySlug'
  payload: string | undefined
}

export type updateUserTokenAction = {
  type: 'updateUserToken'
  payload: UserAuthToken
}

export type updatePdpAction = {
  type: 'updatePdp'
  payload: IPdp
}

export type updateShouldLoadCompanyAction = {
  type: 'updateShouldLoadCompany'
  payload: boolean
}

export type updateShouldLoadUserAction = {
  type: 'updateShouldLoadUser'
  payload: boolean
}

export type updateShouldLoadction = {
  type: 'updateShouldLoad'
  payload: ILoadingState
}

export type updateShouldLoadPersonalPdpAction = {
  type: 'updateShouldLoadPersonalPdp'
  payload: boolean
}

export type updateShouldLoadNewTokenAction = {
  type: 'updateShouldLoadNewToken'
  payload: boolean
}

export type updateShouldLoadRequestAction = {
  type: 'updateShouldLoadRequests'
  payload: boolean
}

export type updateShouldLoadFeedbackStatisticsAction = {
  type: 'updateShouldLoadFeedbackStatistics'
  payload: boolean
}

export type updateShouldLoadFeedbackAggregations = {
  type: 'updateShouldLoadFeedbackAggregations'
  payload: boolean
}

export type updateShouldLoadOnboardingInstance = {
  type: 'updateShouldLoadOnboardingInstance'
  payload: string | undefined
}

export type updatePersonalGoalsAction = {
  type: 'updatePersonalGoals'
  payload: IGoal[]
}

export type updateAlertPopupAction = {
  type: 'updateAlertPopup'
  payload?: IAlertPopup
}

export type updateNotificationAction = {
  type: 'updateNotification'
  payload?: INotification
}

export type updateUserPdpAction = {
  type: 'updateUserPdp'
  payload?: IPdp
}

export type updateUserRequestsAction = {
  type: 'updateUserRequests'
  payload: IRequest[]
}

export type updateFeedbackAggregationsAction = {
  type: 'updateFeedbackAggregations'
  payload: ICompanyFeedbackAggregation
}

export type updateCompanyMetaDataAction = {
  type: 'updateCompanyMetaData'
  payload: ICompanyMetadataPure
}

export type updateOnboardingInstanceAction = {
  type: 'updateOnboardingInstance'
  payload: IOnboardingInstancePopulated
}

export type Action =
  | updateStateAction
  | updateUserAction
  | updateThemeAction
  | updateCompanyAction
  | updateNewCompanySlugAction
  | updateUserTokenAction
  | updateUserPdpAction
  | updateShouldLoadRequestAction
  | updateShouldLoadCompanyAction
  | updateShouldLoadUserAction
  | updateShouldLoadction
  | updateShouldLoadPersonalPdpAction
  | updateShouldLoadNewTokenAction
  | updateShouldLoadFeedbackStatisticsAction
  | updateShouldLoadFeedbackAggregations
  | updatePersonalGoalsAction
  | updateAlertPopupAction
  | updateNotificationAction
  | updateUserRequestsAction
  | updateFeedbackAggregationsAction
  | updateCompanyMetaDataAction
  | updateOnboardingInstanceAction
  | updateShouldLoadOnboardingInstance

export type Dispatch = (action: Action) => void

// Contexts
export const AppStateCtx = createContext<State | undefined>(undefined)

export const AppDispatchCtx = createContext<Dispatch | undefined>(undefined)

// Reducer
export const appReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'updateState': {
      return {
        ...action.payload,
      }
    }
    case 'updateUser': {
      return {
        ...state,
        user: action.payload,
      }
    }
    case 'updateTheme': {
      return {
        ...state,
        theme: action.payload,
      }
    }
    case 'updateCompany': {
      return {
        ...state,
        company: action.payload,
      }
    }
    case 'updateNewCompanySlug': {
      return {
        ...state,
        newCompanySlug: action.payload,
      }
    }
    case 'updateUserToken': {
      return {
        ...state,
        userAuthToken: action.payload,
      }
    }
    case 'updateShouldLoadRequests': {
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          shouldLoadRequests: action.payload,
        },
      }
    }
    case 'updateShouldLoadCompany': {
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          shouldLoadCompany: action.payload,
        },
      }
    }
    case 'updateShouldLoadUser': {
      return {
        ...state,
        loadingState: { ...state.loadingState, shouldLoadUser: action.payload },
      }
    }
    case 'updateShouldLoadPersonalPdp': {
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          shouldLoadPersonalPdp: action.payload,
        },
      }
    }
    case 'updateShouldLoadNewToken': {
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          shouldLoadNewToken: action.payload,
        },
      }
    }
    case 'updateShouldLoadFeedbackStatistics': {
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          shouldLoadFeedbackStatistics: action.payload,
        },
      }
    }
    case 'updateShouldLoadFeedbackAggregations': {
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          shouldLoadFeedbackAggregations: action.payload,
        },
      }
    }
    case 'updateShouldLoadOnboardingInstance': {
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          shouldLoadOnboardingInstance: action.payload,
        },
      }
    }
    case 'updateShouldLoad': {
      return {
        ...state,
        loadingState: { ...state.loadingState, ...action.payload },
      }
    }
    case 'updatePersonalGoals': {
      return {
        ...state,
        personalGoals: action.payload,
      }
    }
    case 'updateAlertPopup': {
      return {
        ...state,
        alertPopup: action.payload,
      }
    }
    case 'updateNotification': {
      return {
        ...state,
        notification: action.payload,
      }
    }
    case 'updateUserPdp': {
      return {
        ...state,
        userPdp: action.payload,
      }
    }
    case 'updateUserRequests': {
      return {
        ...state,
        requests: action.payload,
      }
    }
    case 'updateFeedbackAggregations': {
      return {
        ...state,
        feedbackAggregations: action.payload,
      }
    }
    case 'updateCompanyMetaData': {
      return {
        ...state,
        companyMetaData: action.payload,
      }
    }
    case 'updateOnboardingInstance': {
      return {
        ...state,
        onboardingInstance: action.payload,
      }
    }
    default: {
      throw new Error(`Unhandled action type`)
    }
  }
}

// Hooks
export const useAppState = () => {
  const context = useContext(AppStateCtx)

  if (!context) {
    throw new Error('useAppState must be used within a AppProvider')
  }

  return context
}

export const useAppDispatch = () => {
  const context = useContext(AppDispatchCtx)

  if (!context) {
    throw new Error('useAppDispatch must be used within a AppProvider')
  }

  return context
}
