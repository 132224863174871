import { ICompanyMember } from './Company'

export enum GoalType {
  SKILL = 'skill',
  HABIT = 'habit',
  GENERAL = 'general',
}

export enum PdpStatus {
  NOT_EXISTING = 'not_existing',
  DRAFTING = 'drafting',
  PUBLISHED = 'published',
  NOT_PUBLISHED_ON_TIME = 'not_published_on_time',
  IN_EVALUATION = 'evaluation',
}

export enum PdpReviewStatus {
  REQUESTED = 'requested',
  REVIEWED = 'reviewed',
  DISCARDED = 'discarded',
}

export type StateTransitions = 'publish'

export enum GoalChatMessageType {
  SIMPLE_MESSAGE = 'simple_message',
  PDP_CHANGED = 'pdp_changed',
  GOAL_CREATED = 'goal_created',
  EVALUATION_RESULT = 'evaluation_result',
  // should be removed but to avoid errors in endpoints
  APPROVE = 'approve',
  REMOVE_APPROVAL = 'remove_approval',
}

export interface IGoalSubmission {
  _id?: any
  description?: string
  notificationId: number
  dateCreated: Date
  title: string
}

export interface IGoalMessagePure {
  _id?: any
  notificationId: number
  dateCreated: Date
  dateOpened?: Date
  messageType: GoalChatMessageType
  senderId: string
  content?: string
  evaluationValue?: number
  termNumber?: number
  evaluationType?: 'goal' | 'skill'
}

export interface IGoalMessage extends IGoalMessagePure {
  dateSubmitted?: Date
  externalUser?: string
}

export interface IGoalSubmissionFields {
  title: string
  description: string
}

export interface IGoalPure {
  _id?: any
  type: GoalType
  tag?: string
  habitId?: string
  skillId?: string
  dateCreated: Date
  submissions: IGoalSubmission[]
  messages?: IGoalMessagePure[]
  pinnedDate?: Date
  lastEmailSent?: Date
}

export interface IGoal extends IGoalPure {
  journals: IGoalMessage[]
  approver?: ICompanyMember
}

export interface IPdpBasic {
  memberId: string
  status: PdpStatus
}

export interface IPdpPure extends IPdpBasic {
  _id?: any
  goals: IGoalPure[]
  termNumber: number
  aborted?: boolean
  dateCreated: Date
  datePublished?: Date
  reviews?: IPdpReview[]
  lastUnopenedMessagesEmailSent?: Date
}

export interface IPdp extends IPdpPure {
  goals: IGoal[]
}

export type IGoalBody = Pick<IGoalPure, 'messages'> & {
  title: string
  description: string
  type: GoalType
  habitId?: string
  skillId?: string
  dateCreated?: Date
  submissions?: IGoalSubmission[]
}

export interface IPdpReview {
  reviewerId: string
  reviewStatus: PdpReviewStatus
}
