import React, { ComponentType } from 'react'

// Pages
import MainSkeleton from '../components/fallBack/MainSkeleton'

const OnboardingTemplate = React.lazy(
  () => import('../components/pageTypes/onboarding/OnboardingTemplate')
)
const OnboardingJourney = React.lazy(
  () => import('../components/pageTypes/onboarding/OnboardingJourney')
)
const CompanyPage = React.lazy(() => import('../components/pageTypes/Company'))
const CompanySettings = React.lazy(
  () => import('../components/pageTypes/CompanySettings')
)
const ProfileSettings = React.lazy(
  () => import('../components/pageTypes/ProfileSettings')
)
const ProfilePage = React.lazy(
  () => import('../components/pageTypes/ProfilePage')
)
const AddNewGoalPage = React.lazy(
  () => import('../components/pageTypes/AddNewGoal')
)
const CultureEvaluationPage = React.lazy(
  () => import('../components/pageTypes/CultureEvaluation')
)
const EvaluationPage = React.lazy(
  () => import('../components/pageTypes/Evaluation')
)
const GoalsProgress = React.lazy(
  () => import('../components/pageTypes/GoalsProgress')
)
const CultureEvaluationProgress = React.lazy(
  () => import('../components/pageTypes/CultureEvaluationProgress')
)
const TermEvaluationsProgress = React.lazy(
  () => import('../components/pageTypes/TermEvaluationsProgress')
)
const SignInPage = React.lazy(() => import('../components/pageTypes/SignIn'))
const TeamPage = React.lazy(() => import('../components/pageTypes/Team'))
const TeamSettings = React.lazy(
  () => import('../components/pageTypes/TeamSettings')
)
const SwitchCompaniesPage = React.lazy(
  () => import('../components/pageTypes/SwitchCompanies')
)
const ErrorPage = React.lazy(() => import('../components/pageTypes/Error'))
const OnboardingActivitySettings = React.lazy(
  () => import('../components/pageTypes/onboarding/OnboardingActivitySettings')
)
const PeopleUpdate = React.lazy(
  () => import('../components/pageTypes/PeopleUpdate')
)
const OnboardingCreation = React.lazy(
  () => import('../components/pageTypes/onboarding/OnboardingCreation')
)
const OnboardingActivityDetail = React.lazy(
  () => import('../components/pageTypes/onboarding/OnboardingActivityDetail')
)
const Manifesto = React.lazy(() => import('../components/pageTypes/Manifesto'))

const FeedbackFormIntroPage = React.lazy(
  () => import('../components/pageTypes/FeedbackFormIntro')
)

const ExternalFeedbackIntroPage = React.lazy(
  () => import('../components/pageTypes/ExternalFeedbackIntro')
)

const ExternalFeedbackSubmittedPage = React.lazy(
  () => import('../components/pageTypes/ExternalFeedbackSubmitted')
)

const FeedbackOverviewPage = React.lazy(
  () => import('../components/pageTypes/FeedbackOverview')
)

const GivingFeedbackPage = React.lazy(
  () => import('../components/pageTypes/GivingFeedback')
)

const ScriptRunner = React.lazy(
  () => import('../components/pageTypes/ScriptRunner')
)

// Types
import { UserAuthToken } from './../context/AppContext'

export interface IRoute {
  path: string | string[]
  title: string
  component: ComponentType<any>
  exact?: boolean
  appRefreshToken?: UserAuthToken
  fallbackComponent: ComponentType<any>
}

export interface IRoutes extends Array<IRoute> {}

export const paths = {
  // General
  signIn: '/sign-in',
  home: '/',
  pageNotFound: '*',
  switchCompanies: '/switch-companies',
  styleDocs: '/style-docs',
  redirect: '/redirectToHome',
  company: '/company/:tab?/:sub_tab?',
  companySettings: '/company/settings',
  people: '/company/people',
  peopleUpdate: '/company/people/update/:id?',
  insights: '/company/insights',
  team: '/team/:tab?',
  culture: '/company/culture',
  personalProfile: '/profile',
  personalProfileTab: '/profile/:tab_id?',
  peopleProfile: '/company/people/profile/:employee_id/:tab_id?',
  // Evaluation,
  progress: '/company/manage/performance/goals/:termNumber',
  cultureEvalProgress: '/company/manage/performance/culture/:termNumber',
  termEvaluationsProgress: '/company/manage/performance/review/:termNumber',
  cultureEvaluation: '/culture-evaluation/:cultureEvaluationId',
  feedbackFormIntro: '/feedback/form/:feedbackId',
  feedbackOverview: '/feedback/form/:feedbackId/overview',
  externalFeedbackWelcome: '/external-feedback/:externalFeedbackId',
  externalFeedbackIntro: '/external-feedback/:externalFeedbackId/intro',
  externalFeedbackOverview: '/external-feedback/:externalFeedbackId/overview',
  externalFeedbackSubmitted: '/external-feedback/:externalFeedbackId/submitted',
  editAssessment:
    '/feedback/form/:feedbackId/competency/:competencyId/assessment/:assessmentId/edit',
  evaluation: '/evaluation/:evaluationId',
  evaluationGoals: '/evaluation/:evaluationId/goals',
  evaluationSkills: '/evaluation/:evaluationId/skills',
  peopleSettings: '/company/people/profile/:employee_id/settings',
  teamSettings: '/team/:team_id/settings',
  addNewGoal: '/profile/add-new-goal',
  // Onboarding
  onboardingTemplate: '/company/onboarding/template/:journey_id',
  onboardingJourney: '/company/onboarding/journey/:onboarding_id',
  onboardingActivitySettings:
    '/company/onboarding/activity-settings/:activity_id?',
  onboardingCreation: '/company/onboarding/create/:employee_id',
  onboardingActivityDetail:
    '/company/onboarding/activity/:activity_id/:template_id?',
  // Manifesto
  manifesto: '/manifesto/:slug',
  scripts: '/scripts/:scriptName',
}

const routes: IRoutes = [
  {
    component: Manifesto,
    exact: true,
    path: paths.manifesto,
    title: 'Manifesto page',
    fallbackComponent: MainSkeleton,
  },
  {
    component: SignInPage,
    exact: true,
    path: paths.signIn,
    title: 'Sign in',
    fallbackComponent: MainSkeleton,
  },
  {
    component: ProfilePage,
    exact: true,
    path: paths.home,
    title: 'Personal Profile',
    fallbackComponent: MainSkeleton,
  },
  {
    component: SwitchCompaniesPage,
    exact: true,
    path: paths.switchCompanies,
    title: 'Switch companies',
    fallbackComponent: MainSkeleton,
  },
  {
    component: OnboardingTemplate,
    exact: false,
    path: paths.onboardingTemplate,
    title: 'Onboarding Template',
    fallbackComponent: MainSkeleton,
  },
  {
    component: OnboardingJourney,
    exact: false,
    path: paths.onboardingJourney,
    title: 'Onboarding Journey Overview',
    fallbackComponent: MainSkeleton,
  },
  {
    component: OnboardingActivitySettings,
    exact: false,
    path: paths.onboardingActivitySettings,
    title: 'Onboarding Activity Settings',
    fallbackComponent: MainSkeleton,
  },
  {
    component: OnboardingCreation,
    exact: false,
    path: paths.onboardingCreation,
    title: 'Onboarding Creation',
    fallbackComponent: MainSkeleton,
  },
  {
    component: OnboardingActivityDetail,
    exact: false,
    path: paths.onboardingActivityDetail,
    title: 'Onboarding Activity Detail',
    fallbackComponent: MainSkeleton,
  },
  {
    component: GoalsProgress,
    exact: true,
    path: paths.progress,
    title: 'Progress',
    fallbackComponent: MainSkeleton,
  },
  {
    component: CultureEvaluationPage,
    exact: true,
    path: paths.cultureEvaluation,
    title: 'Culture Evaluation',
    fallbackComponent: MainSkeleton,
  },
  {
    component: FeedbackFormIntroPage,
    exact: true,
    path: paths.feedbackFormIntro,
    title: 'Feedback Form Intro',
    fallbackComponent: MainSkeleton,
  },
  {
    component: FeedbackOverviewPage,
    exact: true,
    path: paths.feedbackOverview,
    title: 'Feedback Overview',
    fallbackComponent: MainSkeleton,
  },
  {
    component: GivingFeedbackPage,
    exact: true,
    path: paths.editAssessment,
    title: 'Edit assessment',
    fallbackComponent: MainSkeleton,
  },
  {
    component: ExternalFeedbackIntroPage,
    exact: true,
    path: paths.externalFeedbackWelcome,
    title: 'External Feedback Welcome',
    fallbackComponent: MainSkeleton,
  },
  {
    component: ExternalFeedbackSubmittedPage,
    exact: true,
    path: paths.externalFeedbackSubmitted,
    title: 'External Feedback Submitted',
    fallbackComponent: MainSkeleton,
  },
  {
    component: FeedbackFormIntroPage,
    exact: true,
    path: paths.externalFeedbackIntro,
    title: 'External Feedback Intro',
    fallbackComponent: MainSkeleton,
  },
  {
    component: FeedbackOverviewPage,
    exact: true,
    path: paths.externalFeedbackOverview,
    title: 'External Feedback Overview',
    fallbackComponent: MainSkeleton,
  },
  {
    component: EvaluationPage,
    exact: true,
    path: paths.evaluation,
    title: 'Evaluation',
    fallbackComponent: MainSkeleton,
  },
  {
    component: EvaluationPage,
    exact: true,
    path: paths.evaluationGoals,
    title: 'Evaluation – Goals',
    fallbackComponent: MainSkeleton,
  },
  {
    component: EvaluationPage,
    exact: true,
    path: paths.evaluationSkills,
    title: 'Evaluation – Skills',
    fallbackComponent: MainSkeleton,
  },
  {
    component: CultureEvaluationProgress,
    exact: false,
    path: paths.cultureEvalProgress,
    title: 'Culture Evaluation Progress',
    fallbackComponent: MainSkeleton,
  },
  {
    component: TermEvaluationsProgress,
    exact: false,
    path: paths.termEvaluationsProgress,
    title: 'Term Evaluations Progress',
    fallbackComponent: MainSkeleton,
  },
  {
    component: ProfileSettings,
    exact: false,
    path: paths.peopleSettings,
    title: 'Profile Settings',
    fallbackComponent: MainSkeleton,
  },
  {
    component: ProfilePage,
    exact: false,
    path: paths.peopleProfile,
    title: 'Employee Profile',
    fallbackComponent: MainSkeleton,
  },
  {
    component: TeamSettings,
    exact: false,
    path: paths.teamSettings,
    title: 'Team edit',
    fallbackComponent: MainSkeleton,
  },
  {
    component: TeamPage,
    exact: true,
    path: paths.team,
    title: 'Team',
    fallbackComponent: MainSkeleton,
  },
  {
    component: AddNewGoalPage,
    exact: true,
    path: paths.addNewGoal,
    title: 'Add New Goal',
    fallbackComponent: MainSkeleton,
  },
  {
    component: ProfilePage,
    exact: false,
    path: paths.personalProfileTab,
    title: 'Personal Profile',
    fallbackComponent: MainSkeleton,
  },
  {
    component: PeopleUpdate,
    exact: false,
    path: paths.peopleUpdate,
    title: 'People Update',
    fallbackComponent: MainSkeleton,
  },
  {
    component: CompanySettings,
    exact: true,
    path: paths.companySettings,
    title: 'Company Edit',
    fallbackComponent: MainSkeleton,
  },
  {
    component: CompanyPage,
    exact: false,
    path: paths.company,
    title: 'Company',
    fallbackComponent: MainSkeleton,
  },
  {
    component: ScriptRunner,
    exact: false,
    path: paths.scripts,
    title: 'ScriptRunner',
    fallbackComponent: MainSkeleton,
  },
  {
    component: ErrorPage,
    exact: false,
    path: paths.pageNotFound,
    title: '404',
    fallbackComponent: MainSkeleton,
  },
]

export default routes
