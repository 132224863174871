import { AnyColor } from './colors'
import { ICompanyMember, ICompanyMemberPure, ICompanyLevel } from './Company'

export enum FeedbackDirection {
  TOP = 'top',
  TIP = 'tip',
}
export enum CompetencyType {
  skill = 'skill',
  habit = 'habit',
}
export enum FeedbackStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
}

export enum FeedbackType {
  REQUESTED = 'requested',
  PLANNED = 'planned',
  VOLUNTARY = 'voluntary',
}

export interface IExternalFeedbacker {
  fullName: string
  email: string
  emailSent: boolean
}
export interface IFeedbackPure {
  _id?: any
  companyId: string
  memberId: string
  senderId?: string
  plannerId?: string
  dateCreated: Date
  dateSubmitted?: Date
  status: FeedbackStatus
  termNumber: number
  personalMessage?: string
  externalFeedbackId?: string
  externalFeedbacker?: IExternalFeedbacker
  assessment: IFeedbackAssessmentPure[]
  competencies: IFeedbackCompetencyPure[]
  feedbackType: FeedbackType
}

export interface IFeedback extends IFeedbackPure {
  member: ICompanyMember
  sender?: ICompanyMember
}

export type IFeedbackBasics = Pick<
  IFeedbackPure,
  | '_id'
  | 'senderId'
  | 'status'
  | 'memberId'
  | 'externalFeedbackId'
  | 'externalFeedbacker'
  | 'feedbackType'
  // (old)
  // | 'isFeedbackRequested'
>
export type IFeedbackPrivacyBasics = Pick<
  IFeedbackPure,
  '_id' | 'senderId' | 'memberId' | 'status' | 'feedbackType'
>

export interface IFeedbackPlanningModal {
  assignedFeedbacksPerMember: Map<string, number>
  feedbacksOverview: IFeedbackOverview[]
}

export interface IFeedbackOverview {
  memberId: string
  assessments: IFeedbackBasics[]
}

export interface IRequestInternalFeedback {
  focusPointsIds?: string[]
  senderIds: string[]
  personalMessage?: string
  feedbackType: FeedbackType
}

export interface IFeedbackAssessmentPure {
  _id?: any
  competencyId: string
  competency: CompetencyType
  textFeedback: IFeedbackFormText[]
}

export interface IFeedbackCompetencyPure {
  competencyId: string
  competency: CompetencyType
  focusPoint?: boolean
  priority?: boolean
}

export interface IFeedbackCompetencyWithAssessments
  extends IFeedbackCompetencyPure {
  numberAssessments?: number
}

export interface IFeedbackCompetency extends IFeedbackCompetencyPure {
  _id?: any
  name: string
  description: string
  levels?: ICompanyLevel[]
  numberAssessments?: number
  color?: AnyColor
  subtitle?: string
  inactive?: boolean
}

// This interface should be used from the frontend to create a request
export interface IFeedbackAssessmentRequest {
  competencyId: string
  competency: CompetencyType
  textFeedback: IFeedbackFormText[]
}

export interface IFeedbackFormText {
  _id?: any
  text: string
  direction: FeedbackDirection
}

export interface IFeedbackMemberResultAggregation {
  memberId: string
  external: boolean
  results: { [key: string]: number }
}

export interface IMemberFeedbackStatisticsPure {
  chartData: IFeedbackMemberResultAggregation[] | undefined
  feedbacks: IFeedbackPure[]
}

export interface IMemberFeedbackStatistics
  extends IMemberFeedbackStatisticsPure {
  feedbacks: IFeedback[]
  chartDataMapInternal: ICompanyFeedbackAggregation | undefined
  chartDataMapExternal?: ICompanyFeedbackAggregation | undefined
}

export interface IFeedbackPhaseStatus {
  planningStarted: boolean
  planningFinalized: boolean
  feedbackCompleted: boolean
}

export interface IExternalFeedback {
  member: ICompanyMemberPure
  teamInfoDetails?: {
    level?: ICompanyLevel
    teamName: string
    teamIcon: string
  }
  memberCompanyName: string
  memberCompetencies: IFeedbackCompetency[]
  companyLogo: string
  companyColors: { [key: string]: number[] }
  personalMessage?: string
}

export interface IExternalFeedbackRequest {
  externalFeedbackers: IExternalFeedbackerRequest[]
  focusPointsIds: string[]
  personalMessage?: string
}

export interface IExternalFeedbackerRequest {
  fullName: string
  email: string
}

export type ICompanyFeedbackAggregation = Map<string, { [key: string]: number }>

export interface IFeedbackHighlightsAggregation {
  direction: FeedbackDirection
  competencyId: string
  competency: CompetencyType
  directionCount: number
}

export interface IFeedbackTopContributorsAggregation {
  contributorId: string
  count: number
}
export interface IFeedbackCompetencyStatisticsAggregation {
  competencyId: string
  competency: CompetencyType
  tipCount: number
  topCount: number
  senders?: Array<{
    name: string
    memberId: string
    picture: string
  }>
}

export interface IFeedbackCompetencyStatistics {
  currentSkills?: IFeedbackCompetencyStatisticsAggregation[]
  currentHabits: IFeedbackCompetencyStatisticsAggregation[]
  previousSkills?: IFeedbackCompetencyStatisticsAggregation[]
  previousHabits?: IFeedbackCompetencyStatisticsAggregation[]
}
